<template>
    <div v-if="currentIncident">
        <b-card :header="`Incident: ${currentIncident.incidentNo}`" header-bg-variant="secondary"
            header-text-variant="white" no-body class="mb-1">
            <b-card-body v-if="currentIncident">
                <b-card-title>{{ currentIncident.whatHappenned }}</b-card-title>
                <b-card-text>
                    <b>Reported By:</b> {{ currentIncident.initiatedByName }}
                    <br>
                    <b>Reported On:</b> {{ currentIncident.incidentDateTime | formatLongDateTime }}
                </b-card-text>
            </b-card-body>
        </b-card>
        <b-card :header="twilioUserName" v-if="currentIncident" class="stuck-div">
            <div class="chat-conversation">
                <div data-simplebar ref="stuckDiv">
                    <ul class="conversation-list">
                        <template v-for="message in conversationList">
                            <li class="clearfix odd" v-if="message.state.author === twilioUserHandle">
                                <div class="chat-avatar">
                                    <img src="@assets/images/users/avatar-5.jpg" alt="male">
                                    <i>{{ message.state.dateUpdated | formatTime }}</i>
                                </div>
                                <div class="conversation-text">
                                    <div class="ctext-wrap">
                                        <i>{{ message.state.attributes.friendlyName }}</i>
                                        <p>{{ message.state.body }}</p>
                                    </div>
                                </div>
                            </li>
                            <li v-else class="clearfix">
                                <div class="chat-avatar">
                                    <img src="@assets/images/users/avatar-5.jpg" alt="male">
                                    <i>{{ message.state.dateUpdated | formatTime }}</i>
                                </div>
                                <div class="conversation-text">
                                    <div class="ctext-wrap">
                                        <i>{{ message.state.attributes.friendlyName }}</i>
                                        <p>{{ message.state.body }}</p>
                                    </div>
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>
            </div> <!-- end .chat-conversation-->
            <div class="needs-validation" name="chat-form" id="chat-form">
                <div class="row">
                    <div class="col">
                        <b-form-input class="form-control chat-input" id="message-input" aria-autocomplete="none"
                            @keyup.enter="sendMessage" placeholder="type a message" v-model="newMessage"></b-form-input>
                    </div>
                    <div class="col-auto">
                        <button @click="sendMessage"
                            class="btn btn-danger chat-send w-100 waves-effect waves-light">Send</button>
                    </div>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>

import { Client as ConversationsClient } from "@twilio/conversations"
import pastIncident from "./pastIncident"
import messageSound from '@assets/sound/message-received.mp3';

import {
    incidentData
} from '@/src/state/api/incident';

export default {
    data() {
        return {
            currentDateTime: new Date(),
            newMessage: "",
            conversationList: [],
            currentIncident: null,
            statusString: "",
            isConnected: false,
            conversationsClient: null,
            twilioUserName: null,
            twilioUserHandle: null,
            twilioUser: null,
            conversationHandle: null,
            activeConversation: null
        }
    },
    components: {
        pastIncident
    },
    computed: {
        locationId() {
            return this.$route.params.locationId;
        },
        incidentId() {
            return this.$route.params.incidentId;
        },
    },
    async created() {
        this.twilioUserHandle = this.$store.getters.loggedInUser.mobile;
        this.twilioUserName = this.$store.getters.loggedInUser.name;
        await this.loadIncident();
        await this.setupTokenForUser();
        await this.setupTwilioUser();
    },
    methods: {
        async loadIncident() {
            if (!this.incidentId) {
                return;
            }

            this.currentIncident = await incidentData.searchMyIncident(this.incidentId);
            if (this.currentIncident) {
                this.conversationHandle = this.currentIncident.incidentHandle
            }
        },
        setupTokenForUser: async function () {
            window.conversationsClient = ConversationsClient
            const result = await incidentData.generateToken(this.twilioUserHandle)
            if (!result.succeeded) {
                return;
            }

            this.conversationsClient = new ConversationsClient(result.data.token)
            this.statusString = "Connecting to Twilio…"
            this.conversationsClient.on("connectionStateChanged", (state) => {
                switch (state) {
                    case "connected":
                        this.statusString = "You are connected."
                        this.isConnected = true
                        break
                    case "disconnecting":
                        this.statusString = "Disconnecting from Twilio…"
                        break
                    case "disconnected":
                        this.statusString = "Disconnected."
                        break
                    case "denied":
                        this.statusString = "Failed to connect."
                        break
                }
            })
        },
        setupTwilioUser: async function () {
            if (this.conversationsClient) {
                this.twilioUser = await this.conversationsClient.getUser(this.twilioUserHandle)
                this.activeConversation = await this.conversationsClient.getConversationByUniqueName(this.currentIncident.id)
                await this.activeConversation.getMessages()
                    .then((newMessages) => {
                        this.conversationList = [...this.conversationList, ...newMessages.items]
                        this.scrollToElement()
                        new Audio(messageSound).play();
                    })

                this.activeConversation.on("messageAdded", (newMessage) => {
                    this.conversationList = [...this.conversationList, newMessage]
                })
            }
        },
        selectedIncident(incident) {
            this.currentIncident = incident;
        },
        async createIncident(message) {

            var payload = {
                locationId: this.locationId,
                incidentMessage: message,
                requestDateTime: this.currentDateTime
            }

            var result = await incidentData.createIncident(payload);
            if (!result.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: result.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }

            this.conversationList.push({
                class: '',
                chatTime: new Date,
                senderName: this.$store.getters.loggedInUser.name,
                message: message
            })
            this.newMessage = ''
        },
        sendMessage() {
            if (!this.newMessage) {
                return;
            }

            this.activeConversation.sendMessage(this.newMessage, {
                friendlyName: this.twilioUserName
            })
                .then(async () => {

                    var payload = {
                        incidentId: this.currentIncident.id,
                        newMessage: this.newMessage,
                        senderName: this.twilioUserName
                    }

                    await incidentData.whenIncidentMessageAdded(payload);

                    this.newMessage = ""
                    this.scrollToElement()
                })
        },
        scrollToElement() {
            if (this.$refs.stuckDiv) {
                this.$refs.stuckDiv.scrollTop = this.$refs.stuckDiv.scrollHeight;
            }
        }
    }
}
</script>

<style>
#chat-form {
    position: fixed;
    left: 0;
    bottom: 60px;
    width: 100%;
    background-color: #dddddd;
    color: white;
    text-align: center;
    padding: 10px;
}

.stuck-div {
    max-height: calc(100vh - 360px);
    overflow-y: auto;
}
</style>